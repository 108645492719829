<template>
  <div>
    <!-- <div class="bg-header"> -->

    <!-- <div class="card">
        <span style="color: #606266;font-size:15px">学生姓名：</span>
        <el-input v-model="form.student_keyword"></el-input>
      </div>

      <div style="float:right">
        <el-button type="primary"
                   @click="search"
                   icon="el-icon-search">查询</el-button>
        <el-button @click="reset">
          <img style="width:14px;height:14px"
               src="@/assets/reset.png">
          重置
        </el-button>
      </div> -->
    <!-- </div> -->

    <div class="bg-content">

      <div class="in-margin">
        <el-table :data="pagedData"
                  highlight-current-row
                  stripe
                  border>
          <el-table-column prop="id"
                           label="题号">
            <template slot-scope="{row}">
              第{{row.question_num}}题
            </template>
          </el-table-column>
          <el-table-column prop="question_type_name"
                           label="题目类型">
            <template slot-scope="{row}">
              {{getType(row)}}
            </template>
          </el-table-column>
          <el-table-column prop="paper_name"
                           label="阅卷老师">
            <template slot-scope="{row}">
              {{getNames(row)}}
            </template>
          </el-table-column>
          <el-table-column prop="correct_num"
                           label="批改题目数">
            <template slot-scope="{row}">
              {{getCorrectNum(row)}}
            </template>
          </el-table-column>
          <el-table-column prop="sheet_status"
                           label="操作">
            <template slot-scope="scope">
              <div class="text_link"
                   @click="toAddTeacher(scope.row,scope.$index)">添加老师</div>
            </template>
          </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination @current-change="handleCurrentChange"
                         :current-page.sync="currentPage"
                         :page-size="pageSize"
                         layout="total, prev, pager, next"
                         :total="totalElements"></el-pagination>
        </div>
      </div>
    </div>
    <CheckTeacher ref="checkTeacherRef" />
  </div>
</template>

<script>


import CheckTeacher from './checkTeacher.vue'
import { getTeacherList, getQuestionNumber } from '@/api/exam.js'
export default {
  data () {
    return {
      title: '',
      type: '',
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      tableLoading: false,
      list: [],
      teacherList: [],
      activeIndex: 0,
      questionNumber: [],
      pagedData: [],
      pageSize: 10,
      currentPage: 1
    }
  },
  components: {
    CheckTeacher
  },
  created () {

  },
  mounted () {
    // this.setTablesColums()
    this.getList()
    this.getTeacher()

  },
  computed: {

  },
  methods: {
    calculatePagedData () {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.list.slice(start, end);
    },
    handleCurrentChange (val) {
      console.log(`每页 ${val} 条`);
      this.currentPage = val;
      this.pagedData = this.calculatePagedData();
    },
    setQN (data, arr = []) {
      let list = data.map(item => {
        let obj = {
          question_num: item,
          edu_user: []
        }
        console.log('arr', arr)
        if (arr.length) {
          let user = arr.filter(row => row.question_num == obj.question_num)
          if (user.length) {
            obj.edu_user = [...user]
          }
        }
        return obj
      })
      console.log('list', list)
      this.list = list
      this.pagedData = list
      this.questionNumber = list
      // this.$refs.allotRef.list = this.questionNumber
    },
    async getFirst (list) {
      const { data } = await getQuestionNumber({ edu_paper_id: this.$route.query.edu_paper_id })
      this.totalElements = data.length
      this.setQN(data, list)
    },

    submit () {
      let dataList = []
      this.list.map(item => {
        console.log(item)
        item.edu_user.map((row) => {
          let arr = {

            question_num: item.question_num,
            edu_user_id: row.edu_user_id

          }
          dataList.push(arr)
        })


      });
      let form = {
        edu_paper_id: this.$route.query.edu_paper_id,
      }
      form.correct_user_data = dataList
      this.$http({
        url: '/api/v1/papers/set_question_number_correct',
        method: 'post',
        data: form
      }).then(res => {
        // this.getFirst(res.data.list)
         this.getList()
        this.$message.success('添加成功')
      })


    },
    setData (arr) {
      this.list[Number(this.activeIndex)].edu_user = arr
      this.pagedData[Number(this.activeIndex)].edu_user = arr
      console.log('dada', this.list)
      // this.submit()
    },
    async getTeacher () {
      let params = {
        subject_id: this.$route.query.subject_id
      }
      const { data } = await getTeacherList(params)
      this.teacherList = data
      setTimeout(() => {
        this.handleCurrentChange(this.currentPage)
      }, 500)

    },
    getNames (row) {
      let str = ''
      row.edu_user.forEach(item => {
        str += item.edu_user_name + ','
      });
      let newStr = str.slice(0, -1)
      return newStr
    },
    getType (row) {
      let str = ''
      row.edu_user.forEach(item => {
        str += item.question_type_name + ','
      });
      let newStr = str.slice(0, -1)
      return newStr
    },
    getCorrectNum (row) {
      let str = ''
      row.edu_user.forEach(item => {
        str += item.correct_num + ','
      });
      let newStr = ''
      newStr = str.slice(0, -1)
      return newStr
    },
    getList () {
      let params = {
        edu_paper_id: this.$route.query.edu_paper_id
      }
      this.$http({
        url: '/api/v1/papers/question_number_correct_detail',
        method: 'get',
        params
      }).then(res => {
        console.log('dasgads', res)
        this.list = res.data.list
        this.getFirst(res.data.list)
        setTimeout(() => {
          this.handleCurrentChange(this.currentPage)
        }, 500)
      })
    },
    toAddTeacher (row, index) {

      console.log('index', index)
      this.activeIndex = index
      this.$refs.checkTeacherRef.allData = this.teacherList
      this.$refs.checkTeacherRef.list1 = JSON.parse(JSON.stringify(row.edu_user))
      // console.log('this.$refs.checkTeacherRef.list1 )
      this.$refs.checkTeacherRef.total = this.teacherList.length
      this.$refs.checkTeacherRef.initData()
      this.$refs.checkTeacherRef.dialogVisible = true
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },

    // initData (page, limit) {
    //   if (page) {
    //     this.page = page
    //   }
    //   if (limit) {
    //     this.size = limit
    //   }
    //   this.$http({
    //     url: '/api/v1/papers/question_number_correct_detail',
    //     method: 'get',
    //     params: {
    //       page: this.page,
    //       limit: this.size,
    //       edu_paper_id: this.$route.query.edu_paper_id,
    //       // page,
    //       // limit,
    //       // ...this.form
    //     }
    //   }).then(res => {
    //     // this.tableData = res.data.list
    //     // this.tableData = [{ question_num: 1 }]
    //     this.totalElements = res.data.count
    //     this.getFirst(res.data.list)

    //   })
    // },

    refresh () {
      this.$refs.pagination.shuaxin()
    }
  },


}
</script>

<style lang="scss" scoped>
.el-input {
  width: 18%;
}
.card {
  margin-left: 66px;
  display: inline;
}
.el-button--primary.is-plain {
  color: #3f9eff;
  background: #ecf5ff;
  border-color: #b3d8ff;
}
.el-button--primary:focus,
.el-button--primary:hover {
  background: #3f9eff;
  border-color: #3f9eff;
  color: #fff;
}
</style>